import React from "react";
import './Contacts.scss';
import { Contact } from "./Contact";

export const Contacts = ({ contacts }) => (
    <div className="ContactBank-Contacts">
        <div className="ContactBank-Box">
            {contacts.slice(0,2).map((contact) => <Contact key={contact.key} contact={contact} />)}
        </div>
            {contacts.slice(2).map((contact) => <div key={contact.key} className="ContactBank-Box">
                <Contact contact={contact} />
            </div>)}
    </div>
);