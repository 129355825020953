import iconBusiness from '../../images/icons/contacts/business.svg';
import iconUser from '../../images/icons/contacts/user.svg';
import { ACTIONS_FORMS } from '../../components/ContactBank/Contacts/Contact';

export default {
    "seo": {
        "title": "Связаться с банком  | Банк 131",
        "description": "Связаться с Банк 131"
    },
    "title": "Связаться с банком",
    "contacts": [
        {   
            "key": 11111,
            "icon": iconBusiness,
            "name": "Для обращений <br /> юридических лиц и ИП",
            "text": "Написать",
            "action": ACTIONS_FORMS.company,
        },
        {   
            "key": 22222,
            "icon": iconUser,
            "name": "Для Обращений физических лиц",
            "text": "Написать",
            "action": ACTIONS_FORMS.user,
        }
    ],
    "faq": {
        "title" : "Возможно мы уже ответили на ваш вопрос здесь: ",
        "link": {
            "title" : "FAQ: вопросы и ответы",
            "href": "/faq",
        }
    },
    "info": `
        <p>Срок рассмотрения обращения составляет <span>15 рабочих дней</span> со дня его регистрации, если иной срок не установлен законом или договором. Срок рассмотрения обращения может быть продлен на 10 рабочих дней в случае необходимости запроса дополнительных документов и материалов.</p>

        <p>Обращаем ваше внимание на то, что не подлежат рассмотрению обращения, если:<br />
            а) в обращении не указан адрес, по которому должен быть направлен ответ;<br />
            б) в обращении не указана фамилия (наименование) заявителя;<br />
            в) в обращении содержатся нецензурные либо оскорбительные выражения, угрозы имуществу Банка, угрозы жизни, здоровью и имуществу работника Банка, а также членов его семьи;</br>
            г) текст обращения не поддается прочтению;<br />
            д) текст обращения не позволяет определить его суть;<br />
            е) в обращении содержится вопрос, на который заявителю неоднократно давались ответы по существу в связи с ранее направляемыми им обращениями, и при этом не приводятся новые доводы или обстоятельства.
        </p>

        <p><a href="/legal/ombudsman/">Подробная информация</a> о праве потребителей финансовых услуг на направление обращения финансовому уполномоченному.</p>
    `
}