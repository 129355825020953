import iconBusiness from '../../images/icons/contacts/business.svg';
import iconUser from '../../images/icons/contacts/user.svg';
import { ACTIONS_FORMS } from '../../components/ContactBank/Contacts/Contact';

export default {
    "seo": {
        "title": "Contact the bank | Bank 131",
        "description": "Contact the Bank 131"
    },
    "title": "Contact the bank",
    "contacts": [
        {
            "key": 11111,
            "icon": iconBusiness,
            "name": "For requests from <br /> legal entities and individual entrepreneurs",
            "text": "Contact with us",
            "action": ACTIONS_FORMS.company,
        },
        {
            "key": 22222,
            "icon": iconUser,
            "name": "For Applications from individuals",
            "text": "Contact with us",
            "action": ACTIONS_FORMS.user,
        }
    ],
    "faq": {
        "title" : "We may have already answered your question here: ",
        "link": {
                "title" : "FAQ: questions and answers",
                "href": "/en/faq",
            }
    },
    "info": `
        <p>The period for consideration of an application is <span>15 working days</span> from the date of its registration, unless a different period is established by law or agreement. The period for consideration of the application may be extended by 10 working days if it is necessary to request additional documents and materials.</p>

        <p>Please note that applications are not subject to consideration if:<br />
        a) the appeal does not indicate the address to which the response should be sent;<br />
        b) the surname (name) of the applicant is not indicated in the appeal;<br />
        c) the message contains obscene or offensive language, threats to the Bank’s property, threats to the life, health and property of a Bank employee, as well as members of his family;</br>
        d) the text of the appeal is not readable;<br />
        e) the text of the appeal does not allow us to determine its essence;<br />
        f) the appeal contains a question to which the applicant has been repeatedly given substantive answers in connection with his previously sent appeals, and no new arguments or circumstances are presented.
        </p>

        <p><a href="/legal/ombudsman/">Detailed information</a> about the right of consumers of financial services to send an appeal to the financial ombudsman.</p>
    `
}