import React from 'react';

import Block from "../blocks/Block";
import Layout from "../layout/Layout";
import SEO from "../components/seo";

import enMessages from '../i18n/contacts/en.js';
import ruMessages from '../i18n/contacts/ru.js';
import ContactBank from '../components/ContactBank';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const PayoutPage = ({ pathContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title={messages[locale].seo.title}
      description={messages[locale].seo.description}
    />

    <Block config={{
      baseBorder: true,
      animation: '3'
    }}>
      <div className="container">
        <ContactBank locales={messages[locale]}/>
      </div>
    </Block>
  </Layout>
);

export default PayoutPage;
