import React from 'react';
import * as PropTypes from "prop-types";
import { Formik, Field, Form }  from "formik";
import * as yup from 'yup';

import Interpolate from 'react-interpolate-component';

import useLanguage from "../../../hooks/useLanguage.js";

import iconClip from "../../../images/icons/form/clip.svg";
import iconCheck from "../../../images/icons/form/check.svg";
import iconLoading from "../../../images/icons/form/loading.svg";
import iconRepeat from "../../../images/icons/form/repeat.svg";

import { createInternalLink } from '../../../utils/createInternalLink';

import Button from "../../../components/Button.js";

import {
  MESSAGES, 
  FILE_STATUSES, 
  FORM_FIELDS, 
  FORM_TEXTAREA_MAXLENGTH, 
  MAX_COUNT_FILES, 
  UPLOADED_FILES
} from './constants.js';

const FosForm = React.memo(({ onSubmit }) => {
  const [language] = useLanguage();
  const locales = MESSAGES[language];

  const inputFileRef = React.useRef(null);

  const [initialValues, setInitialValues] = React.useState(FORM_FIELDS);
  const [fileAddingStatus, setFileAddingStatus] = React.useState(FILE_STATUSES.default);
  const [fileErrorInfo, setFileErrorInfo] = React.useState('');
  const [uploadedFiles, setUploadedFiles] = React.useState(UPLOADED_FILES);

  const fileBtnData = React.useMemo(
    () => {
      switch (fileAddingStatus) {
        case FILE_STATUSES.finish:
          return {
            img: {
              alt: 'Replace file',
              src: iconRepeat,
            },
            title: locales.fileButton.replaceFile,
          };

        case FILE_STATUSES.process:
          return {
            img: {
              alt: 'Uploading a file',
              src: iconLoading,
            },
            title: locales.fileButton.loadingFile,
          };

        case FILE_STATUSES.success:
          return {
            img: {
              alt: 'File downloaded',
              src: iconCheck,
            },
            title: locales.fileButton.loadingFileSuccess
          };

        default:
          return {
            img: {
              alt: 'Attach file',
              src: iconClip,
            },
            title: locales.fileButton.selectFile,
          };
      }
    },
    [fileAddingStatus],
  );

  const handleFileBtnClick = React.useCallback(
    () => inputFileRef?.current && inputFileRef.current.click(),
    [inputFileRef.current],
  );

  const validationSchema = yup.object({
    contactName: yup
      .string(locales.fieldValidation.incorrectName)
      .min(2, locales.fieldValidation.incorrectName)
      .required(locales.fieldValidation.requaredName),
    contactPatronymic: yup
      .string(locales.fieldValidation.incorrectPatronymic)
      .min(2, locales.fieldValidation.incorrectPatronymic),
    contactSurname: yup
      .string(locales.fieldValidation.incorrectSurname)
      .min(2, locales.fieldValidation.incorrectSurname)
      .required(locales.fieldValidation.requiredSurname),
    comment: yup
      .string(locales.fieldValidation.incorrectComment)
      .min(5, locales.fieldValidation.incorrectMinLengthComment)
      .required(locales.fieldValidation.requiredComment),
    email: yup
      .string(locales.fieldValidation.incorrectEmail)
      .email(locales.fieldValidation.incorrectEmail)
      .required(locales.fieldValidation.requiredEmail),
    phone: yup
      .string(locales.fieldValidation.incorrectPhone)
      .matches(/^((\+79|\+78|89|88)+([0-9]){9})$/, locales.fieldValidation.incorrectPhone)
      .required(locales.fieldValidation.requiredPhone),
  });

  React.useEffect(() => {
    return () => {
      if (fileAddingStatus !== FILE_STATUSES.default) {
        setFileAddingStatus(FILE_STATUSES.default);
      }

      if (uploadedFiles.length) {
        setUploadedFiles(UPLOADED_FILES);
      }

      setInitialValues(FORM_FIELDS);
    };
  }, [uploadedFiles, setFileAddingStatus, setUploadedFiles, setInitialValues]);

  return (
    <Formik
      initialStatus="default"
      initialValues={initialValues}
      onSubmit={(values) => onSubmit({...values, files: uploadedFiles})}
      validationSchema={validationSchema}
    >
      {({ errors, values, touched }) => (
        <div>
          <Form className="FieldFormBase" >
            <Field
              className="callBackForm-inputText"
              name="contactSurname"
              placeholder={`${locales.fields.surname}*`}
              type="text"
            />

            {errors.contactSurname && touched.contactSurname
              ? <div className="callBackForm-ValidationError">{errors.contactSurname}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              name="contactName"
              placeholder={`${locales.fields.name}*`}
              type="text"
            />

            {errors.contactName && touched.contactName
              ? <div className="callBackForm-ValidationError">{errors.contactName}</div>
              : null}

            {language === 'ru' && <>
              <Field
                className="callBackForm-inputText"
                name="contactPatronymic"
                placeholder={locales.fields.patronymic}
                type="text"
              />

              {errors.contactPatronymic && touched.contactPatronymic
                ? <div className="callBackForm-ValidationError">{errors.contactPatronymic}</div>
                : null}
            </>}

            <Field
              className="callBackForm-inputText"
              name="email"
              placeholder={`${locales.fields.email}*`}
              type="text"
            />

            {errors.email && touched.email
              ? <div className="callBackForm-ValidationError">{errors.email}</div>
              : null}

            <Field
              type="text"
              placeholder={`${locales.fields.phone}*`}
              name="phone"
              className="callBackForm-inputText"
              maxLength={12}
            />

            {errors.phone && touched.phone
              ? <div className="callBackForm-ValidationError">{errors.phone}</div>
              : null}

            <label
              className="textareaLabel"
              htmlFor="userComment"
            >
              {locales.fields.comment}

              <div className="textareaCounter">
                {values.comment.length}/{FORM_TEXTAREA_MAXLENGTH}
              </div>
            </label>

            <Field
              as="textarea"
              className="callBackForm-inputText callBackForm-inputTextArea"
              id="userComment"
              maxLength={FORM_TEXTAREA_MAXLENGTH}
              name="comment"
              placeholder={`${locales.fields.comment}*`}
              type="text"
            />

            {errors.comment && touched.comment
              ? (
                <div className="callBackForm-ValidationError callBackForm-inputTextAreaValidationError">
                  {errors.comment}
                </div>
              )
              : null}

            <label
              className="callBackForm-labelFile"
              htmlFor="userFile"
            >
              <button
                onClick={handleFileBtnClick}
                type="button"
                className={
                  `callBackForm-labelFileBtn ${fileAddingStatus === FILE_STATUSES.success
                    ? 'callBackForm-labelFileBtn-Success'
                    : fileAddingStatus === FILE_STATUSES.finish
                    ? 'callBackForm-labelFileBtn-Repeat'
                    : ''}`}
              >
                <img
                  alt={fileBtnData.img.alt}
                  className={`callBackForm-labelFileImg ${ fileAddingStatus === FILE_STATUSES.process ? 'callBackForm-labelFileLoadingImg' : ''}`}
                  src={fileBtnData.img.src}
                />

                {fileBtnData.title}
              </button>

              {fileAddingStatus === FILE_STATUSES.error &&
                <p className="callBackForm-labelFileErrorText">
                  {locales.fileButton.filetryAgain}{fileErrorInfo && `. ${fileErrorInfo}`}
                </p>
              }

              {uploadedFiles && (
                <p className="callBackForm-labelAddFileNames">
                  {uploadedFiles.map((file) => file.name).join(', ')}
                </p>
              )}
            </label>

            <Field name="files">
              {({ field, meta }) => (
                <>
                  <input
                    {...field}
                    ref={inputFileRef}
                    id="userFile"
                    type="file"
                    multiple='multiple'
                    accept=".pdf, .png, .jpg, .jpeg"
                    onChange={(event) => {
                      setFileErrorInfo('');
                      setInitialValues({...values});
                      setFileAddingStatus(FILE_STATUSES.process);
                      setUploadedFiles(UPLOADED_FILES);

                      const files = event?.currentTarget?.files;
                      const readFiles = [].map.call(files, file => {
                        const fileReader = new FileReader();

                        return new Promise((resolve, reject) => {
                          fileReader.onload = () => {
                            resolve(file);
                          }

                          fileReader.onerror = () => reject('Ошибка чтения файла!');

                          if (/pdf|png|jpg|jpeg/.test(file.type)) {
                            fileReader.readAsDataURL(file)
                          } else {
                            reject('Можно загружать только pdf|png|jpg|jpeg!')
                          }
                        });
                      });

                      Promise.all(readFiles)
                        .then((files) => {
                          if (files?.length > MAX_COUNT_FILES) {
                            setFileAddingStatus(FILE_STATUSES.error);
                            setFileErrorInfo(locales.incorectMaxFiles);

                            return null;
                          }

                          setUploadedFiles([...files]);
                          setFileAddingStatus(FILE_STATUSES.success);

                          const successBtnTimeout = setTimeout(
                            () => {
                              setFileAddingStatus(FILE_STATUSES.finish);

                              return clearTimeout(successBtnTimeout);
                            },
                            1000,
                          );
                        })
                        .catch(() => {
                          setFileAddingStatus(FILE_STATUSES.error);
                        });
                        if (!event) {
                          field.onChange(event);
                        }
                    }}
                  />

                  {meta.touched && meta.error && (
                    <div className="callBackForm-ValidationError">{meta.error}</div>
                  )}
                </>
              )}
            </Field>

            <div className="callBackForm-agreement formBlockAgreementFos">
              <label>
                <Field type="checkbox" name="confirmation"/>

                  <Interpolate
                    with={{
                      linkToConsentProcessingPD: (
                        <a
                          href={createInternalLink(language, '/consent-processing-pd/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {locales.confirmationConsentProcessingPD}
                        </a>
                      ),
                      linkToPrivacyPolicy: (
                        <a
                          href={createInternalLink(language, '/dengiru/privacy-policy/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {locales.confirmationPrivacyPolicy}
                        </a>
                      ),
                    }}
                  >
                    {locales.confirmationLabel}
                  </Interpolate>
              </label>
            </div>

            <Button
              disabled={!values.confirmation}
              type="submit"
            >
              {locales.submit}
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
});

FosForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FosForm;
