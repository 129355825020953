import { VerificationType } from '../../../constants.js';
import enMessages from '../../../i18n/form/en.js';
import ruMessages from '../../../i18n/form/ru.js';

export const MESSAGES = {
  en: enMessages,
  ru: ruMessages,
};

export const FORM_FIELDS = {
  companyName:'',
  companyIdentificationNumber: '',
  comment: '',
  confirmation: false,
  contactName: '',
  contactSurname: '',
  contactPatronymic: '',
  email: '',
  files: '',
  phone: '',
};

export const verificationFormType = typeof window !== 'undefined' && window.GATSBY_VERIFICATION_TYPE_FOR_CONTACTS_COMPANY_FORM 
? window.GATSBY_VERIFICATION_TYPE_FOR_CONTACTS_COMPANY_FORM
: process.env.GATSBY_VERIFICATION_TYPE_FOR_CONTACTS_COMPANY_FORM || VerificationType.Email; 
