import React from "react";
import './Faq.scss';
import LinkButton from "../LinkButton";
export const Faq = ({ locales }) => ( 
    <div className="ContactBank-Faq">
        <h2 className="ContactBank-FaqTitle">{locales.title}</h2>
        <div className="ContactBank-FaqBtnBox">
        <LinkButton 
            className="ContactBank-LinkButtonBtnFaq" 
            href={locales.link.href} 
            title={locales.link.title}
        />
        </div>
    </div>
);