export { MESSAGES, FORM_FIELDS } from '../../instances/SignUpCompanyForm/constants.js'

export const FORM_TEXTAREA_MAXLENGTH = 256;

export const FILE_STATUSES = {
  default: 'default',
  error: 'error',
  finish: 'finish',
  process: 'process',
  success: 'success',
};

export const MAX_COUNT_FILES = 10;
export const UPLOADED_FILES = [];
