import React from 'react';
import * as PropTypes from "prop-types";

import FormVerificationWrapper from '../../components/FormVerificationWrapper/FormVerificationWrapper.js';
import CompanyForm from '../../components/CompanyForm/CompanyForm.js';

import {
  FORM_FIELDS,
  verificationFormType
} from './constants.js';

import { sendCompanyDataForm } from '../../models/form'; 

const SignUpCompanyForm = ({ openFormLinkTitle, canBeFixed = "false" }) => {

  return (
    <FormVerificationWrapper
      canBeFixed={canBeFixed}
      form={CompanyForm}
      formFieldValues={FORM_FIELDS}
      openFormLinkTitle={openFormLinkTitle}
      reachGoalTitle="fos_v5_send"
      verificationType={verificationFormType}
      submitForm={sendCompanyDataForm}
    />
  );
};

SignUpCompanyForm.propTypes = {
  canBeFixed: PropTypes.oneOf(['true', 'false']),
  openFormLinkTitle: PropTypes.string,
};

export default SignUpCompanyForm;
