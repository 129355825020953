import React from "react";
import './ContactBank.scss';
import { Contacts } from "./Contacts";
import { Info } from "./Info";
import { Faq } from "./FAQ";

export const ContactBank = ({locales}) => (
  <div className="ContactBank">
    <div className="ContactBank-Container">
      <h1 className="ContactBank-Title">{locales.title}</h1>

      <Contacts contacts={locales.contacts} />
      <Faq locales={locales.faq} />
      <Info locales={locales.info} />
    </div>
  </div>
);
